import { get } from 'lodash';
import { ITEM_TYPES } from '@wix/advanced-seo-utils';
import { IWixWindow } from '@wix/yoshi-flow-editor';
import {
  createPageUrl,
  getCategoryUrl,
  getImageUrl,
  SECTION_CATEGORY,
  getFullLanguageCode,
  getMultilingualQueryParam,
  resolveId,
  resolveLegacyId,
  SharedLocale,
  AppConfig,
  NormalizedCategory,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { OOI_EXPERIMENTS } from '../../../../experiments';
import { getQueryLocale } from '../../selectors/locale-selectors';
import {
  getEntityCount,
  getPaginationPageSize,
} from '../../selectors/pagination-selectors';
import { getPostsByCategoryIdAndPage } from '../../selectors/post-selectors';
import { getCategoriesMap } from '../../store/categories/categories-selectors';
import { getIsSitePropertiesLoaded } from '../../store/is-loaded/is-loaded-selectors';
import { fetchSiteProperties } from '../../store/site-properties/site-properties-actions';
import { getSiteProperties } from '../../store/site-properties/site-properties-selectors';
import {
  getSectionUrl,
  getPostPageSectionUrl,
} from '../../store/topology/topology-selectors';
import { AppStore } from '../../types';
import { getCategorySchema } from '../json-schema';
import { getPaginationItemData } from './get-pagination-item-data';

type Translation = {
  language: string;
  url: { base: string; path: string };
};

const mapTranslationData = (
  translation: Translation,
  siteLanguages: SharedLocale[],
) => {
  const langCode = getFullLanguageCode(siteLanguages, translation.language);
  return {
    langCode,
    url: `${translation.url.base}${translation.url.path}`,
  };
};

const getTranslatedPagesData = (
  translations: Translation[],
  siteLanguages: IWixWindow['multilingual']['siteLanguages'] = [],
) =>
  translations.map((translation: Translation) =>
    mapTranslationData(translation, siteLanguages),
  );

type GenerateCategorySEOTagsParams = {
  appConfig: AppConfig;
  sectionUrl: string;
  category: NormalizedCategory;
  store: AppStore;
  page: number;
  multilingual: IWixWindow['multilingual'];
};

export const generateCategorySEOTags = async ({
  appConfig,
  sectionUrl,
  category,
  store,
  page,
  multilingual,
}: GenerateCategorySEOTagsParams) => {
  let state = store.getState();
  const isMultilingualEnabled = Boolean(getQueryLocale(state));
  const pageSize = getPaginationPageSize(state, SECTION_CATEGORY);
  const entityCount = getEntityCount(state, 'posts');
  const categoryUrl = getCategoryUrl(
    sectionUrl,
    appConfig.categoryPath,
    category.slug,
  );
  const multilingualQueryParam = getMultilingualQueryParam(multilingual);
  const categoryUrlWithPage = `${createPageUrl(
    page,
    categoryUrl || getSectionUrl(state),
  )}${multilingualQueryParam}`;

  let itemData: any = {};

  if (isExperimentEnabled(state, OOI_EXPERIMENTS.CATEGORY_SEO_PLATFORMIZED)) {
    itemData = {
      ...category,
      coverImage: category.cover,
      displayPosition: category.rank,
      language: category.language ?? '',
      postCount: category.postAmount,
    };
  } else {
    const title = appConfig.useCategoryMenuLabelForMetadataTitle
      ? category.menuLabel
      : category.label;

    itemData = {
      title,
      label: category.menuLabel,
      description: category.description,
      canonicalUrl: categoryUrlWithPage,
      items: { numberOfItems: category.postAmount },
      pagination: {
        totalPages: Math.ceil(entityCount / pageSize),
        currentPage: page,
      },
    };
  }

  const mixedCategoryId = resolveLegacyId(category) ?? resolveId(category);

  if (appConfig.isWP || state?.tpaSettings?.settings?.schemaEnabled) {
    if (!getIsSitePropertiesLoaded(state)) {
      await store.dispatch(fetchSiteProperties());
    }

    state = store.getState();

    itemData.structuredData = JSON.stringify(
      getCategorySchema({
        posts: getPostsByCategoryIdAndPage(state, mixedCategoryId, page),
        category,
        categoryMap: getCategoriesMap(state),
        schemaEnabled: state?.tpaSettings?.settings?.schemaEnabled,
        appConfig,
        siteProperties: getSiteProperties(state),
        sectionUrl,
        postPageSectionUrl: getPostPageSectionUrl(state),
        multilingual,
      }),
    );
  }

  const itemDataWithPagination = getPaginationItemData({
    itemData,
    state,
    url: categoryUrl,
    page,
    multilingualQueryParam,
  });

  if (category.cover) {
    const { width, height } = category.cover;
    const image = {
      url: getImageUrl({
        image: category.cover,
      }),
      width,
      height,
      hasImage: true,
    };

    itemDataWithPagination.mainImage = image;
  }

  if (isMultilingualEnabled) {
    itemDataWithPagination.language = getFullLanguageCode(
      multilingual.siteLanguages,
      category.language as string,
    );
    itemDataWithPagination.translatedPages = get(
      category,
      'translations.length',
    )
      ? getTranslatedPagesData(
          category.translations as Translation[],
          multilingual.siteLanguages,
        )
      : [];
  }

  return {
    itemType: ITEM_TYPES.BLOG_CATEGORY,
    itemData: {
      category: itemDataWithPagination,
      legacySeoData: {
        title: category.label ?? '',
        description: category.description ?? '',
      },
    },
    seoData: category.seoData,
  };
};
